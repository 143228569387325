.loading-mask {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5); /* 透明度を持つ黒い背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.loader {
  border: 4px solid #f3f3f3; /* ローダーの背景色 */
  border-top: 4px solid #3498db; /* ローダーの上部の色 */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

.background-diagonal-lines {
  background: repeating-linear-gradient(
    -45deg,
    #2e3192,
    #2e3192 22px,
    #6e6fb6 20px,
    #6e6fb6 40px
  );
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
