/* FormForPayment Component Styles */

.formforpayment-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.formforpayment-form {
  background-color: #fff;
  border-radius: 12px;
  max-width: 480px;
  width: 100%;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.formforpayment-title {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  text-align: center;
  color: #333;
}

.formforpayment-field {
  margin-bottom: 1rem;
}

.formforpayment-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #555;
  text-align: left;
}

.formforpayment-input {
  width: 100%;
  padding: 0.75rem;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.formforpayment-input:focus {
  border-color: #4a90e2;
}

.formforpayment-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.formforpayment-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.formforpayment-button {
  width: 100%;
  background-color: #4a90e2;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition:
    transform 0.2s,
    background-color 0.2s;
}

.formforpayment-button:hover {
  background-color: #3c7bc6;
  transform: scale(1.02);
}

.formforpayment-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
