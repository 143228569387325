.carousel__wrapper {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 50px auto;
}

.carousel {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.carousel::-webkit-scrollbar {
  display: none;
}

.carousel__viewport {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel__slide {
  min-width: 100%;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.carousel__slide img {
  width: 100%;
  max-width: 400px;
  min-height: 160px;
  display: block;
  border-radius: 16px;
  object-fit: contain;
}

.carousel__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  padding: 1rem;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__button--prev {
  left: 10px;
}

.carousel__button--next {
  right: 10px;
}

.carousel__indicators {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 12px;
}

.carousel__indicator {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.carousel__indicator.active {
  background-color: white;
}
