/* @tailwind base;
@tailwind components;
@tailwind utilities; */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer utilities {
  .rotate-345 {
    transform: rotate(345deg);
  }
}

.my-rotated-image {
  @apply transform rotate-345;
}

.bg-jgs-secondary {
  @apply bg-gray-200;
}

.btn-primary {
  @apply bg-jgs-main hover:bg-jgs-main-hovered text-white font-bold py-2 px-4 rounded-full;
}

.btn-admin {
  @apply bg-jgs-admin text-white font-bold py-2 px-4 rounded-full;
}

.btn-cancel {
  @apply bg-gray-500 text-white font-bold py-2 px-4 rounded-full;
}

.btn-point {
  @apply bg-black hover:bg-jgs-main-hovered text-yellow-300 font-bold py-2 px-8 rounded-full;
}

.btn-price {
  @apply px-4 py-1 -mt-1.5 rounded-full shadow-sm font-bold text-lg bg-yellow-300;
}

.btn-tag {
  @apply px-[16px] py-[10px] rounded-[30px] font-bold flex-none cursor-pointer border-[1px] border-gray-300;
}

.btn-tag-active {
  @apply bg-yellow-300 border-none;
}

.btn-tag-default {
  @apply bg-white;
}

.btn-go-back {
  @apply flex items-center bg-gray-400 text-white font-bold py-1 px-2 rounded-full;
}

.btn-go-back-transparent {
  @apply flex items-center text-gray-500 font-bold py-1 px-2 rounded-full;
}

.input-main {
  @apply border rounded px-4 py-2 w-[100%] bg-gray-200;
}

.input-title-main {
  @apply text-start;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
